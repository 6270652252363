<template>
    <div class="RadarEvaluation_box" v-if="UserhistoryList.id">
        <div class="dimension_graph" ref="chartContainer"></div>
        <div class="graph_name flex-align-center" :class="fontSizeStyle[IndexNum]">{{ UserhistoryList.shortName
        }}</div>
    </div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['UserhistoryList', 'IndexNum'],
    data() {
        return {
            fontSizeNum: {
                1: 24,
                2: 24,
                3: 19,
                4: 18,
                5: 18,
                6: 18,
            },
            fontSizeStyle: {
                1: 'graph_name1',
                2: 'graph_name1',
                3: 'graph_name1',
                4: 'graph_name4',
                5: 'graph_name4',
                6: 'graph_name4',
            },
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {
        UserhistoryList: {
            handler(newVal, oldVal) {
                this.dimension_graph();
            },
            deep: true,
        },
    },
    // 方法集合
    methods: {
        dimension_graph() {
            let value = [this.UserhistoryList.centerPos, this.UserhistoryList.gridSize, this.UserhistoryList.glyphGrav, this.UserhistoryList.rewindRel, this.UserhistoryList.multiDist, this.UserhistoryList.strokeRel, this.UserhistoryList.comboRel, this.UserhistoryList.integrity];
            let newdata1 = [
                {
                    value: this.UserhistoryList.centerPos,
                    grade: this.computeLevel(this.UserhistoryList.centerPos),
                    name: '居中占位',
                },
                {
                    value: this.UserhistoryList.gridSize,
                    grade: this.computeLevel(this.UserhistoryList.gridSize),
                    name: '占格大小',
                },
                {
                    value: this.UserhistoryList.glyphGrav,
                    grade: this.computeLevel(this.UserhistoryList.glyphGrav),
                    name: '字形重心',
                },
                {
                    value: this.UserhistoryList.rewindRel,
                    grade: this.computeLevel(this.UserhistoryList.rewindRel),
                    name: '收放关系',
                },
                {
                    value: this.UserhistoryList.multiDist,
                    grade: this.computeLevel(this.UserhistoryList.multiDist),
                    name: '多画等距',

                },
                {
                    value: this.UserhistoryList.strokeRel,
                    grade: this.computeLevel(this.UserhistoryList.strokeRel),
                    name: '笔画关系',
                },
                {
                    value: this.UserhistoryList.comboRel,
                    grade: this.computeLevel(this.UserhistoryList.comboRel),
                    name: '组合关系',
                },
                {
                    value: this.UserhistoryList.integrity,
                    grade: this.computeLevel(this.UserhistoryList.integrity),
                    name: '作品完整度',
                }


            ];
            let that = this;
            const chartContainer = this.$refs.chartContainer;
            this.$echarts.dispose(chartContainer);
            const myChart = this.$echarts.init(chartContainer);
            let splitColor = "#fff";
            let fontSize = this.fontSizeNum[this.IndexNum]
            myChart.setOption({
                tooltip: {
                    show: true,
                    trigger: 'item',
                    showContent: true,
                    textStyle: {
                        fontSize: this.graphicRatio(17),
                    },
                    formatter: (data) => {
                        console.log('data.seriesName',data);
                        
                        var tip = data.name;
                        // let tmpData = [];
                        // if (data.seriesIndex === 0) {
                        //     tmpData = newdata1;
                        // }
                        data.value.forEach((item, index) => {
                            tip += '<div>';
                            tip += '<div>' + '<span>'+newdata1[index].name+'</span>' + ': ' + newdata1[index].grade + '</div>'
                            tip += '</div>';
                        });
                        return tip;
                    },
                },
                radar: {
                    center: ["50%", "50%"], // 外圆的位置
                    radius: "60%",
                    axisName: {
                        color: '#000',
                        // color: "#033333",
                        fontSize: this.graphicRatio(fontSize),
                        // fontWeight: 'bold',
                        fontFamily: "PingFangSC-Regular,PingFang SC",
                        fontStyle: "normal",
                    },
                    indicator: [
                        { name: '居中占位', max: 100 },
                        { name: '占格大小', max: 100 },
                        { name: '字形重心', max: 100 },
                        { name: '收放关系', max: 100 },
                        { name: '多画等距', max: 100 },
                        { name: '笔画关系', max: 100 },
                        { name: '组合关系', max: 100 },
                        { name: '作品完整度', max: 100 },
                    ]
                    ,
                    splitArea: {
                        // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                        show: true,
                        areaStyle: {
                            // 分隔区域的样式设置。
                            color: ["RGBA(228, 229, 235, 1)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                            // color: ["RGBA(228, 229, 235, 1)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                        },
                    },
                    axisLine: {
                        // 指向外圈文本的分隔线样式
                        lineStyle: {
                            color: splitColor,
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            type: "solid",
                            color: splitColor, // 分隔线颜色
                            width: this.graphicRatio(1), // 分隔线线宽
                        },
                    },
                },
                series: [{
                    type: "radar",
                    symbolSize: this.graphicRatio(10),
                    symbol: "none",
                    itemStyle: {
                        borderColor: "FFD900",
                        // borderColor: "#03ce99",
                        // borderColor: "rgba(3, 206, 153, 0.7)",
                        // color: "#fff",
                        borderWidth: this.graphicRatio(0.2),
                    },
                    lineStyle: {
                        width: this.graphicRatio(1),
                        color: '#9b95f7',
                        // color: '#03ce99',
                    },
                    data: [{
                        name: '等级',
                        value: newdata1.map((item) => item.value),
                        label: {
                            fontSize: this.graphicRatio(20),
                            formatter: '{value}%'
                        },
                        areaStyle: {
                            color: "rgba(155, 149, 247, 1)"
                            // color: "rgba(3, 206, 153, 0.7)"
                        },
                    },],
                },
                ],

            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        computeLevel(score) {
            if (score >= 85) {
                return '优秀'
            }
            if (score >= 70 && score < 85) {
                return '良好'
            }
            if (score >= 60 && score < 70) {
                return '合格'
            }
            if (score < 60) {
                return '待提升'
            }
        },
    },

    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.dimension_graph();
    },
}
</script>
<style scoped lang='less'>
.RadarEvaluation_box {
    width: 100%;
    width: 100%;

    .dimension_graph {
        width: 100%;
        height: calc(100% - 38px);
    }



    .graph_name1 {
        font-size: 24px;
    }

    .graph_name4 {
        font-size: 18px;
    }

    .graph_name1 {
        font-size: 24px;
    }

    .graph_name {
        // width: 100px;
        width: 100%;
        display: inline-block;
        height: 38px;
        // font-weight: bold;
        color: #000;
        // color: #033333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }
}
</style>